<template>
  <div>
    <v-hover v-slot="{hover}"  >
        <wsTooltip :text="tooltip">
          <router-link
              :is="!to ? 'span' : 'router-link'"
              :to="to"
              class="noUnderline d-flex"  >

            <v-sheet
                @click="$emit('click')"
                v-if="!circle"
                v-ripple
                style="border-radius: 4px"
                :color="hover ? wsBACKGROUND : 'transparent'"
                class="transition-ease-in-out d-flex justify-center">
              <v-icon :small="small" style="margin-left: 2px"  :color="wsACCENT">{{ icon }} </v-icon>
            </v-sheet>

            <v-btn v-else
                   @click="$emit('click')"
                   :color="color ? color : wsATTENTION"
                   large
                   dark
                   icon  >
              <v-avatar  :size="size">
                <v-sheet
                    :color="color ? color : wsATTENTION"
                    :height="size"
                    :width="size"
                    class="d-flex align-center justify-center">

                  <v-icon size="20" >{{ icon }}</v-icon>

                </v-sheet>
              </v-avatar>
            </v-btn>

          </router-link>
        </wsTooltip>
    </v-hover>
  </div>

</template>

<script>
export default {
  name: "wsIconButton",
  props : {
    icon : {
      type : String,
      default : 'mdi-dots-horizontal'
    },
    to : {
      type : String,
      default : ''
    },
    small : {
      type : Boolean,
      default : false
    },
    color : {
      type : String,
      default : ''
    },
    circle : {
      type : Boolean,
      default : false
    },
    size : {
      type : String,
      default : '32'
    },
    tooltip : {
      type : String,
      default : ''
    }
  }
}
</script>

<style scoped>

</style>